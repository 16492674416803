<style>
.add-owner {
    float: right;
}

.ranges {
    max-height: 200px;
    overflow: scroll;
}



.alert-primary {
    width: 100%;
}
</style>
<template>
    <div>

        <!-- Filters -->
        <b-card no-body>
            <b-card-header class="pb-50">
                <h5>Filter Location</h5>
            </b-card-header>
            <b-card-body>

                <b-row v-if="role.id == 5">
                    <b-col cols="3">
                        <b-form-group labe-for="region_id" label="Region">
                            <validation-provider #default="{ errors }" name="Region" rules="">
                                <b-form-select id="region_id" name="region_id" size="md" v-model="filter.region_id"
                                    @change="changeRegion(filter.region_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="location in locations" :key="location.id"
                                        :value="location">
                                        {{ location.name.toUpperCase() }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.region_id">{{
                                    serverErrors.region_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="3">
                        <b-form-group labe-for="location_id" label="Area">
                            <validation-provider #default="{ errors }" name="Area" rules="">
                                <b-form-select id="location_id" name="location_id" size="md" v-model="filter.location_id"
                                    @change="getAreaMarket(filter.location_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="location in locId.children" :key="location.id"
                                        :value="location.id">
                                        {{ location.name.toUpperCase() }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.location_id">{{
                                    serverErrors.location_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                    </b-col>

                    <b-col cols="3">
                        <b-form-group labe-for="market_id" label="Market Center">
                            <validation-provider #default="{ errors }" name="Market Center" rules="">
                                <b-form-select id="market_id" name="market_id" size="md" v-model="filter.market_id"
                                    @change="getAreaMarketSale(filter.market_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="market in markets" :key="market.id" :value="market.id">
                                        {{ market.name.toUpperCase() }} ({{ market.code }})
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.market_id">{{
                                    serverErrors.market_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="3">
                        <b-form-group labe-for="sale_id" label="Sale">
                            <validation-provider #default="{ errors }" name="Sale" rules="">
                                <b-form-select id="sale_id" name="sale_id" size="md" v-model="filter.sale_id"
                                    @change="changeSale($event)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="sale in sales" :key="sale.id" :value="sale">
                                        {{ sale.number }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.sale_id">{{
                                    serverErrors.sale_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="3">
                        <b-form-group labe-for="society_id" label="Society">
                            <validation-provider #default="{ errors }" name="Society" rules="">
                                <b-form-select id="society_id" name="society_id" size="md" v-model="filter.society_id"
                                    @change="changeSociet(filter.society_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="society in societies" :key="society.id"
                                        :value="society.id">
                                        {{ society.name }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.society_id">{{
                                    serverErrors.society_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row v-if="role.id != 5">
                    <b-col cols="3" v-if="locId.id > 0">
                        <b-form-group labe-for="location_id" label="Area">
                            <validation-provider #default="{ errors }" name="Area" rules="">
                                <b-form-select id="location_id" name="location_id" size="md" v-model="filter.location_id"
                                    @change="getAreaMarket(filter.location_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="location in locId.children" :key="location.id"
                                        :value="location.id">
                                        {{ location.name.toUpperCase() }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.location_id">{{
                                    serverErrors.location_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                    </b-col>
                    <b-col cols="3" v-if="locId.id > 0">
                        <b-form-group labe-for="market_id" label="Market Center">
                            <validation-provider #default="{ errors }" name="Market Center" rules="">
                                <b-form-select id="market_id" name="market_id" size="md" v-model="filter.market_id"
                                    @change="getAreaMarketSale(filter.market_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="market in markets" :key="market.id" :value="market.id">
                                        {{ market.name.toUpperCase() }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.market_id">{{
                                    serverErrors.market_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="3" v-if="locId.id > 0">
                        <b-form-group labe-for="sale_id" label="Sale">
                            <validation-provider #default="{ errors }" name="Sale" rules="">
                                <b-form-select id="sale_id" name="sale_id" size="md" v-model="filter.sale_id"
                                    @change="changeSale($event)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="sale in sales" :key="sale.id" :value="sale">
                                        {{ sale.number }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.sale_id">{{
                                    serverErrors.sale_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="3" v-if="locId.id > 0">
                        <b-form-group labe-for="society_id" label="Society">
                            <validation-provider #default="{ errors }" name="Society" rules="">
                                <b-form-select id="society_id" name="society_id" size="md" v-model="filter.society_id"
                                    @change="changeSociet(filter.society_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="society in societies" :key="society.id"
                                        :value="society.id">
                                        {{ society.name }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.society_id">{{
                                    serverErrors.society_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>

                        <b-button
                            variant="outline-success"
                            size="md"
                        >
                            <b-spinner small class="mr-1" label="Small Spinner"/>
                            <span class="text-nowrap">Generate</span>
                            <feather-icon icon="DownloadCloudIcon" class="ml-1"/>
                        </b-button>
                <!-- <pre>{{requestForm}}</pre> -->
            </b-card-body>
        </b-card>
        <!-- ./Filters -->

    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
    BForm, BFormGroup,
    BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem,
    BSpinner, BFormCheckbox, BBadge, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import store from '@/store'
import { ref, onUnmounted, onMounted, reactive } from '@vue/composition-api'

import requisitionsStoreModule from '@/views/cromis/sales/requisition/requisitionsStoreModule'
import useRequisitionsList from '@/views/cromis/sales/requisition/useRequisitionsList'
import Panel from 'primevue/panel';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';

export default {
    props: {},
    components: {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem,
        BSpinner,
        ValidationObserver, ValidationProvider, BFormCheckbox, BBadge, BAlert, Panel, Accordion, AccordionTab
    },
    directives: {},
    watch: {
        userInput(newInput, oldInput) {
            // Update default value when user input changes
            if (!isNaN(newInput)) {
                this.defaultValue -= (newInput - oldInput);
            } else {
                // Handle case where userInput is NaN (e.g., after deleting the number)
                this.defaultValue = this.defaultValue;
            }
        },
    },
    setup(props, context) {
        const dataForm = ref(null)
        const myModal = ref(null)
        const myViewModal = ref(null)
        const myModalPcn = ref(null)
        const myRangeModal = ref(null)
        const saving = ref(false)
        const serverErrors = ref(null)
        const errosOcurs = ref(null)
        const selections = ref([])
        const formSelections = ref([])
        const levels = ref(null)
        const requisitionData = ref(null)
        const companies = ref([])
        const societies = ref([])
        const markets = ref([])
        const requisitionDetail = ref(null)
        const locations = ref([])
        const sales = ref([])
        const tikets = ref([])
        const totalWeight = ref(0)
        const tValue = ref(0)
        const children = ref([])
        const requestTicket = ref(false)
        const requisition = ref({
            requisition_id: null,
            type: null,
            ranges: [
                {
                    start: null,
                    end: null,
                    quantity: null
                }
            ]
        })
        const filter = ref({
            region_id: null,
            location_id: null,
            market_id: null,
            sale_id: null,
            society_id: null
        })


        const locId = ref(JSON.parse(localStorage.getItem('userData')).location)
        const role = ref(JSON.parse(localStorage.getItem('userData')).roles[0])
        console.log(locId)


        const handleChange = async (event) => {
               requestTicket.value = event
                }



        const removeRow = (index) => {
            console.log(index)
            if (requisition.value.ranges.length > 1) {
                requisition.value.ranges.splice(index, 1)
                requisition.value.ranges = requisition.value.ranges
            }
        }





        const CROMIS_STORE_MODULE_NAME = 'cromis-requisition'
        // Register module
        if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, requisitionsStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
        })

        onMounted(async () => {


            await store.dispatch('cromis-requisition/locations')
                .then(response => {
                    locations.value = response.data.locations

                    console.log(response)
                })
                .catch(error => {
                    console.log('got error', error)
                })

                await store.dispatch('cromis-requisition/markets', { location_id: filter.value.location_id })
                .then(response => {
                    markets.value = response.data.markets

                    console.log(response)
                })
                .catch(error => {
                    console.log('got error', error)
                })
        })

        const handleCheckboxChange = async (event, id) => {

            console.log(event)
            let payload = {
                "approved": event
            }

            await store.dispatch('cromis-requisition/update', { id: id, data: payload })
                .then(response => {

                    console.log(response)
                    refetch()
                    saving.value = false


                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to Requisition has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    console.log(error)
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }


        const populateSelections = (locations) => {
            for (let i = 0; i < locations.length; i++) {
                for (let x = 0; x < selections.value.length; x++) {
                    if (locations[i].level.name.toLowerCase() === selections.value[x].level) {
                        selections.value[x].locations.push(locations[i])

                        if (x === 0) {
                            formSelections.value[x].locations.push(locations[i])
                        }
                    }
                }
            }
        }

  

        const changeFormSelection = async (location, index) => {
            if (!location) {
                for (let i = index + 1; i < formSelections.value.length; i++) {
                    formSelections.value[i].locations.splice(0)
                    formSelections.value[i].model = null
                }

                requisition.value.location_id = null
            }
            else {
                requisition.value.location_id = null

                if (formSelections.value[index + 1]) {
                    formSelections.value[index + 1].locations.splice(0)
                }

                location.children.map((child) => {
                    formSelections.value[index + 1].locations.push(child)
                })

                if (location.children.length > 0) {
                    formSelections.value[index + 1].model = null
                }

                if (index === formSelections.value.length - 1) {
                    requisition.value.location_id = location.id
                }
            }
        }

        const {
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            // Filers
            resolveStatusVariant,
            resolveReceivedVariant,
            locationFilter,
            societyFilter,
            marketFilter
        } = useRequisitionsList()

        // Form Methods
        const invokeUpdateForm = (item) => {
            serverErrors.value = null
            errosOcurs.value = null


            requisition.value = {
                requisition_id: item.id,
                type: item.type,
                location_id: null,
                ranges: item.ranges,
            }

            myModal.value.show()
        }

        const approveRequisition = (item) => {
            console.log(item)
        }


        const invokeRangeUpdateForm = (par) => {
            formSelections.value.map((form) => {
                form.model = null
            })

            console.log(par)

          

            myRangeModal.value.show()
        }

        const getAreaMarket = async () => {
            locationFilter.value = filter.value.location_id

            await store.dispatch('cromis-requisition/markets', { location_id: filter.value.location_id })
                .then(response => {
                    markets.value = response.data.markets

                    console.log(response)
                })
                .catch(error => {
                    console.log('got error', error)
                })
        }

        const getAreaMarketSale = async () => {
            marketFilter.value = filter.value.market_id
            await store.dispatch('cromis-requisition/sales', { market_id: filter.value.market_id })
                .then(response => {
                    sales.value = response.data.sales
                    societies.value = response.data.sales.societies
                    console.log(response)
                })
                .catch(error => {
                    console.log('got error', error)
                })
        }

        const changeSociet = async (id) => {

            societyFilter.value = id
        }

        const changeSale = async (event) => {
            societies.value = event.societies
            requestForm.value.sale_id = event.id
            // filter.value.sale_id = event.id
        }

        const changeRegion = async (event) => {

            locId.value = event
            console.log(locId.value)
            locationFilter.value = event.id

            await store.dispatch('cromis-requisition/markets', { location_id: locId.value.id })
                .then(response => {
                    markets.value = response.data.markets

                    console.log(response)
                })
                .catch(error => {
                    console.log('got error', error)
                })
        }

        const submit = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
        }

        const submitUpdateRange = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
        }

        const handleUpdateRange = async (item) => {
           
          
        }



        return {
            // Data
            selections,
            formSelections,
            levels,
            children,
            requisition,
            selectedOwner: null,
            addedElement: null,
            clientName: JSON.parse(localStorage.getItem('userData')).client,
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,
            resolveStatusVariant,
            resolveReceivedVariant,
            // Methods

            required,
            saving,
            serverErrors,
            dataForm,
            myModal,
            myModalPcn,
            myViewModal,
            requisitionDetail,
            filter,
            markets,
            locations,
            sales,
            changeSale,
            changeSociet,
            changeRegion,
            myRangeModal,
            changeFormSelection,
            locationFilter,
            societyFilter,
            marketFilter,
            invokeUpdateForm,
            invokeRangeUpdateForm,
            approveRequisition,
            submit,
            submitUpdateRange,
            handleUpdateRange,
            removeRow,
            companies,
            societies,
            errosOcurs,
            locId,
            role,
            getAreaMarket,
            getAreaMarketSale,
            handleCheckboxChange,
            tikets,
            totalWeight,
            tValue,
            requisitionData,
            handleChange,
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>